import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { themeOptions } from "./typography";
import { fontSize, padding } from "@mui/system";

const baseOptions = {

  palette: {
    primary: {
      main: "#fff", // Customize this color as needed
    },
    secondary: {
      main: "#404040", // Customize this color as needed
    },
    background: {
      main: "#080031", // Customize this color as needed
    },
    text: {
      primary: "#080031",
      secondary: "#d32f2f",
      fontSize: "14px"
    },
    // Add more color definitions as needed
  },
  components: {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewButton: {
          "&:hover": {
            backgroundColor: "#544FA7",
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          "&:hover": {
            background: "#544FA7",
          },
          marginRight: "0px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          color: "#757575 !important",
          padding: "12px",
          width: "40px",
          height: "40px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#474747",
          height: "50px",
          borderRadius: "10px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputMultiline: {
          padding: "1px !important",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid rgba(0, 0, 0, 0.1)",
          },
          height: " 45px",
        },
        input: {
          borderRadius: "10px",
          color: "#000",
          padding: "14px 14px",
          fontSize: "14px",
          fontWeight: "300",
          background: "rgba(0, 0, 0, 0.02)",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#000",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&::before": {
            display: "none",
          },
          "&::after": {
            display: "none",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "30px",

        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        outlined: {
          padding: "20px",
          width: "100%",
          backgroundColor: "#544FA7",
        },
        elevation1: {
          backgroundColor: `#fff`,
          borderRadius: "20px",
          border: "1px solid rgba(255, 255, 255, 0.15)",
          padding: "20px",
          boxShadow: "none",
          boxShadow: "3px 5px 11.100000381469727px 2px #0000001A",
        },
        elevation2: {
          background: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          padding: "35px",
          boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.16)",
        },
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: { position: "unset" },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          // borderRadius: "20px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "rgb(59 84 165)",
          "&:hover": {
            backgroundColor: "none",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          background: "rgba(43, 145, 239, 0.1)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#ffffff14",
          },
          "&:last-child": {
            // borderBottom: "none",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: "16px 16px",
          fontWeight: "500",
          color: "#fff",
          whiteSpace: "pre",
          fontSize: "14px",
          textAlign: "left",
        },
        body: {
          color: "#000000",
          whiteSpace: "pre",
          fontSize: "14px",
          fontWeight: "500",
          textAlign: "left",
        },
        root: {
          borderBottom: "15px solid #fff",
          textAlign: "left",
          color: "#000000",
          borderSpacing: "10px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        },
        paper: {
          backgroundColor: "#dbdce1",
        }
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: "self-start",
          padding: "10px 20px 10px 20px",
        },
        gutters: {
          // paddingLeft: 0,
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          background: "rgba(255, 255, 255, 1)",
          boxShadow: "3px 5px 11.100000381469727px 2px rgba(0, 0, 0, 0.1)",
          "&:last-of-type": {
            borderRadius: "10px",
          },
          "&:first-of-type": {
            borderRadius: "10px",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(0, 0, 0, 0.15 ",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "4px",
          fontSize: "12px",
          color: "rgb(255 187 85)",
          "&.Mui-checked": { color: "rgb(255 187 85)" },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          paddingBottom: "0",
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperScrollPaper: {
          Width: 450,
          maxWidth: "100%",
        },
        paper: {
          borderRadius: "10px",
          background: "#fff",
          filter: "none",
        },
        paperWidthSm: {
          maxWidth: "522px !important",
        },
        paperWidthXs: {
          maxWidth: "522px !important",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "20px 18px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // backgroundColor: "rgba(63, 59, 122, 1)",
          textAlign: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "20px 35px 0px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#d32f2f !important",
            fontSize: "14px",
          },
          marginRight: "0px",
          marginLeft: "0px",
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(121 115 255)",
          "&.Mui-disabled": {
            opacity: 0.38,
            background: "rgb(121 115 255)",
            color: "#fff",
          },
          "&.Mui-selected": {
            background: "#ffff",
            "&:hover": {
              backgroundColor: "#fff",
            },
          },
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "0px",
          textAlign: "left",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          // color: "#212121",
          // borderRadius: "30px",
          // border: "1px solid red",
          // textTransform: "capitalize",
          // background: "red",
          // whiteSpace: "pre",
          // boxShadow: "none",
          // "&:hover": {
          //   color: "#212121",
          //   backgroundColor: "red",
          //   border: "1px solid red",
          //   boxShadow: "none",
          // },
          color: "#3B54A5",
          padding: "10px 35px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid #3B54A5",
          whiteSpace: "pre",
          "&:hover": {
            boxShadow: "none",
            color: "#fff",
            background: "#3B54A5",
            border: "1px solid #fff",
          },
        },
        containedOutline: {
          color: "#fff",
          padding: "10px 35px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid #3B54A5",
          background: "#3B54A5",
          whiteSpace: "pre",
          "&:hover": {
            boxShadow: "none",
            color: "#3B54A5",
            // background: "transparent",
            border: "1px solid #3B54A5",
          },
        },
        containedSizeSmall: {
          color: "#3B54A5",
          padding: "7px 15px",
          textTransform: "capitalize",
          fontSize: "12px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid #3B54A5",
          whiteSpace: "pre",
          "&:hover": {
            boxShadow: "none",
            color: "#fff",
            background: "#3B54A5",
            border: "1px solid #fff",
          },
        },
        containedPrimary: {
          color: "#474747",
          padding: "10px 35px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid transparent",
          background: "#FFBB55",
          whiteSpace: "pre",
          "&:hover": {
            boxShadow: "none",
            color: "#474747",
            background: "#fff",
            border: "1px solid #FFBB55",
          },
        },
        containedPrimarySmall: {
          color: "#474747",
          padding: "7px 15px",
          textTransform: "capitalize",
          fontSize: "12px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid transparent",
          background: "#FFBB55",
          whiteSpace: "pre",
          "&:hover": {
            boxShadow: "none",
            color: "#474747",
            background: "#fff",
            border: "1px solid #FFBB55",
          },
        },
        outlinedPrimary: {
          color: "#000",
          padding: "10px 35px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid #59695E",
          background: "transparent",
          whiteSpace: "pre",
          "&:hover": {
            boxShadow: "none",
            color: "#fff",
            background: "#59695E",
            border: "1px solid #59695E",
          },
        },
        outlinedSecondary: {
          color: "#fff",
          padding: "10px 35px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "10px",
          border: "1px solid transparent",
          whiteSpace: "pre",
          border: "1px solid #FFBB55",

          "&:hover": {
            boxShadow: "none",
            color: "#474747",
            background: "#FFBB55",
            border: "1px solid #FFBB55",
          },
        },
        outlined: {
          color: "#2B91EF",
          borderRadius: "unset !important",
          border: "2px solid #3f51b524 !important",
          textTransform: "capitalize",
          whiteSpace: "pre",
          padding: "5px 3px 5px !important",
          fontSize: "16px",
          fontWeight: "400",
          minWidth: "40px",
          "&:hover": {
            color: "#000 !important",
            background: "#dfdfdf !important",
            border: "2px solid #3F51B5 !important"
          },
        },
        activeoutlined: {
          padding: "5px 3px 5px !important",
          minWidth: "40px",
          color: "#000 !important",
          background: "#dfdfdf !important",
          border: "2px solid #3F51B5 !important"

        },
        root: {
          textTransform: "capitalize",
          lineHeight: "20px",
        },
      },

    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          lineHeight: "20px",
          "&.Mui-disabled": {
            color: " #ccc !important",
            background: "#3b54a5db"
          },
        },
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#ffbb55",
          "&.Mui-checked": {
            color: " #3b54a5db !important",
          },
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0px"
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: "0",
        },
        paper: {
          width: "250px",
          backgroundColor: "#dbdce1",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // border: "1px solid #E8E8E8",
          backgroundColor: "transparent",
          height: "50px !important",
          borderRadius: "10px !important",
        },
        select: {
          color: "#474747 !important",
          backgroundColor: "transparent",
          fontSize: 15,
        },
        icon: {
          color: "#474747 !important",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { top: "dd !important" },
        list: {
          background: "#dbdce1",
          color: "#404069",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: { paddingLeft: "20px" },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: "#a7a2ee",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        backdrop: {
          background: "transparent !important",
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        // root: {
        //   padding: "0px 65px !important",
        //   "@media (max-width: 780px)": {
        //     padding: "0px 16px !important",
        //   },
        // },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none !important",
          cursor: "pointer",
        },
      },
    },
  },
};

export const createCustomTheme = (config = {}) => {
  console.log("data12", { ...baseOptions })
  let theme = createTheme({ ...baseOptions, ...themeOptions });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
