import React from "react";
import PropTypes from "prop-types";
import { styled, Box } from "@mui/system";
import { CircularProgress, Typography } from '@mui/material';
const StyledPage = styled("Box")(({ theme }) => ({
    "& .circularProgress": {
        color: "#3b54a5",
        padingRight: "50px",
    },
}));

function PageLoading(props) {
    const { size } = props;
    return (
        <StyledPage>
            <Box
                color="secondary.main"
                display="flex"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginLeft: "50%",
                }}
            >
                <CircularProgress
                    size={size ? size : 30}
                    thickness={size ? (size / 5) * 30 : 5}
                    className="circularProgress"
                />
                <Typography variant='h6'>Loading...</Typography>
            </Box>
        </StyledPage>
    );
}

PageLoading.propTypes = {
    size: PropTypes.number,
    classes: PropTypes.object.isRequired,
};

export default PageLoading;