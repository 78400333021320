import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ButtonCircular from './ButtonCircular';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(5),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));


export default function LogoutModal({ title, handleClose, logoutHandler, open5 }) {

    return (
        <>
            <Box>
                <BootstrapDialog
                    aria-labelledby="customized-dialog-title"
                    open={open5}
                    onClose={handleClose}
                    style={{ padding: "20px" }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Typography variant='h4'>Logout</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Box>
                            <Typography variant='h6'>{title && title} </Typography>

                        </Box>
                        <Box pt={2} className='flex_boxcenter' style={{ display: "flex", justifyContent: "center" }}>
                            <Button autoFocus variant='containedPrimarySmall'

                                onClick={logoutHandler}
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
            </Box>

        </>

    )
}
