import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Button, Typography, Grid } from '@mui/material';
import { styled } from "@mui/system";
// import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const StyledDashboard = styled("Box")(({ theme }) => ({
  // "& .root": {
  //   background: "#2D1639",
  //   display: "flex",
  //   height: "100%",
  //   overflow: "hidden",
  //   width: "100%",
  // },
  "& .wrapper": {
    display: "unset !important",
    display: "dd !important",
    flex: "1 1 auto",
    overflow: "hidden",
    minHeight: "calc(100vh - 80px)",
    backgroundColor: theme.palette.background.darkgrey,
    // backgroundColor: "#1A2C38",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 250,
      // paddingRight: " 30px",
    },
  },
  // "& .contentContainer": {
  //   display: "flex",
  //   flex: "1 1 auto",
  //   overflow: "hidden",
  //   background: theme.palette.primary.paper,
  // },
  // "& .content": {
  //   flex: "1 1 auto",
  //   height: "100%",
  //   width: "100%",
  //   position: "relative",
  //   WebkitOverflowScrolling: "touch",
  //   background: "#f8f8fb",
  // },
}));

const DashboardLayout = ({ children }) => {

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <StyledDashboard>
        <div className="root">
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <div className="wrapper">
            <div className="contentContainer">
              <div className="content" id="main-scroll">
                {children}
              </div>
            </div>
          </div>
        </div>
      </StyledDashboard>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
