import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query/react';
import CartSlice from "./Features/Cart/CartSlice";
import ProductSlice from "./Features/Product/ProductSlice";
import SimilarSlice from "./Features/Product/SimilarSlice";
import TrandingSlice from "./Features/Product/TrandingSlice";
import ProfileSlice from "./Features/Api/ProfileSlice";

let initialState = {};
let store = configureStore(
    {
        reducer: {
            products: ProductSlice,
            carts: CartSlice,
            productssimilar: SimilarSlice,
            trandingproducts: TrandingSlice,
            profiledata: ProfileSlice,
        },
    },
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // Corrected Redux DevTools extension check
);

setupListeners(store.dispatch);

export default store;
