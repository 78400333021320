import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import { styled } from "@mui/system";

const StyledIndex = styled("Box")(({ theme }) => ({
  "& .root": {
    backgroundColor: "#fff",
  },
  "& .MainLayout": {
    minHeight: "calc(100vh - 415px)"
  },
}));


const MainLayout = ({ children }) => {
  const history = useHistory();

  return (
    <StyledIndex>
      <div className="root">
        <TopBar />
        <div
          style={
            history.location.pathname !== "/"
              ? { display: "block" }
              : { display: "none" }
          }
        ></div>

        <div className="MainLayout">{children}</div>
        <Footer />
      </div>
    </StyledIndex>

  );
};

export default MainLayout;
