import React, { lazy } from "react";
import { Redirect } from 'react-router-dom';
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/index")),
  },
  {
    exact: true,
    path: "/means",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Home/Testing")),
  },
  {
    exact: true,
    path: "/womens",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Contact")),
  },
  {
    exact: true,
    path: "/kids",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Contact")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/Profile/ProfileIndex")),
  },
  {
    exact: true,
    path: "/updatePpassword",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/Profile/UpdatePassword")),
  },
  {
    exact: true,
    path: "/traditionalwear",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Contact")),
  },

  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Contact")),
  },
  {
    exact: true,
    path: "/blogs",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Contact")),
  },
  {
    exact: true,
    path: "/login",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/signup",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Auth/Signup")),
  },
  {
    exact: true,
    path: "/forgotpassword",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Auth/ForgotPassword")),
  },
  {
    exact: true,
    path: "/changepassword",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/cardlist",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Details/Carddetails")),
  },
  {
    exact: true,
    path: "/carddetails",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Details/DetailsPage")),
  },
  {
    exact: true,
    path: "/address",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Address")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/index")),
  },
  {
    exact: true,
    path: "/add_product",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/Products/Addproduct")),
  },
  {
    exact: true,
    path: "/productlist",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/Products/Productlist")),
  },
  {
    exact: true,
    path: "/userlist",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/Users/Userlist")),
  },
  {
    exact: true,
    path: "/pincodelist",
    layout: DashboardLayout,
    component: lazy(() => import("./view/pages/Dashboard/Users/Pincodes")),
  },
  {
    exact: true,
    path: "/order",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Orderproduct")),
  },


  {
    component: () => <Redirect to="/404" />,
  },
];
