import { createSlice } from "@reduxjs/toolkit";

const CartSlice = createSlice({
    name: "cart",
    initialState: {
        carts: localStorage.getItem("carts") ? JSON.parse(localStorage.getItem("carts")) : [],
        error: null,
    },
    reducers: {
        addToCarts: (state, action) => {
            if (!state.carts) {
                state.carts = [action.payload]
            } else {
                const existItem = state.carts.find((i) => i.id === action.payload.id);

                if (existItem) {
                    let update = state.carts.map((i) => {
                        if (i.id === action.payload.id) {
                            return { ...i, size: action.payload.size, quantity: i.quantity + action.payload.quantity, salePrice: action.payload.salePrice };
                        } else {
                            return i;
                        }
                    });
                    state.carts = update;

                } else {
                    state.carts = [...state.carts, action.payload];
                }
            }


            localStorage.setItem("carts", JSON.stringify(state.carts))
        },
        increaseQuantity: (state, action) => {
            //find the item from state
            const item = state.carts.find(
                (product) => product.id === action.payload.id
            );

            //if item quantity greater than 1 then increase the quantity by 1
            if (item.quantity >= 1) {
                //if not then decrease the quantity
                state.carts = state.carts.map((product) =>
                    product.id === action.payload.id
                        ? { ...product, quantity: (item.quantity += 1) }
                        : product
                );
            }

            //update the localstorage value
            localStorage.setItem("carts", JSON.stringify(state.carts));
        },
        decreaseQuantity: (state, action) => {
            //find the item from state
            const item = state.carts.find(
                (product) => product.id === action.payload.id
            );

            //if item quantity then on decrease remove the item
            if (item.quantity === 1) {
                state.carts = state.carts.filter(
                    (product) => product.id !== action.payload.id
                );
            }

            //if not then decrease the quantity
            state.carts = state.carts.map((product) =>
                product.id === action.payload.id
                    ? { ...product, quantity: (item.quantity -= 1) }
                    : product
            );

            //update the localstorage value
            localStorage.setItem("carts", JSON.stringify(state.carts));
        },
        removeFormCart: (state, action) => {
            //find the item from state
            const item = state.carts.find(
                (product) => product.id === action.payload.id
            );

            //if item exist then filter the states
            if (item) {
                state.carts = state.carts.filter(
                    (product) => product.id !== action.payload.id
                );
            }

            //update the localstorage value
            localStorage.setItem("carts", JSON.stringify(state.carts));
        },
        // apicalldata: (state, action) => {
        //     const newItem = action.payload;
        //     console.log("item111", newItem)
        //     localStorage.setItem("carts_type", newItem?.type);
        // }
    }
})

export const cartState = (state) => state.carts;

//export reducer
export const {
    addToCarts,
    increaseQuantity,
    decreaseQuantity,
    removeFormCart,
    // apicalldata
} = CartSlice.actions;

export default CartSlice.reducer;
