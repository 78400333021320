import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api_configs } from "src/api-services";
// Thunk to fetch similar products
export const getAllProductssimilar = createAsyncThunk(
    'productssimilar/getAllProductssimilar',
    async () => {
        try {
            const puduct_type = window.localStorage.getItem("carts_type");
            const res = await axios({
                method: "GET",
                url: `${api_configs.listProduct}?product_type=${puduct_type}`,
            });
            if (res.data.responseCode === 200) {
                return res.data.result;
            }
        } catch (error) {
            throw error;
        }
    }
);
// Slice for similar products
const SimilarSlice = createSlice({
    name: 'productssimilar',
    initialState: { isLoading: false, products: [], error: null }, // Adjusted 'productssimilar' to 'products'
    extraReducers: (builder) => {
        builder
            .addCase(getAllProductssimilar.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllProductssimilar.fulfilled, (state, action) => {
                state.isLoading = false;
                state.products = action.payload; // Adjusted 'productssimilar' to 'products'
                state.error = null;
            })
            .addCase(getAllProductssimilar.rejected, (state, action) => {
                state.isLoading = false;
                state.products = [];
                state.error = action.error.message; // Access error message
            });
    },
});

// Selectors to access state from Redux store
export const selectProductssimilar = (state) => state.productssimilar.products; // Adjusted 'productssimilar' to 'products'

// Exporting reducers
export default SimilarSlice.reducer; // Ensure this reducer is combined with others in your Redux store setup
