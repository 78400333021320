import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api_configs } from "src/api-services";

export const getAllTrandingProducts = createAsyncThunk(
    'trandingproducts/getAllTrandingProducts',
    async (_, { getState, rejectWithValue }) => {
        const { isLoading } = getState().trandingproducts;

        if (!isLoading) {
            // If not loading, return a rejected promise with a value to handle this case
            return rejectWithValue('Loading is already false');
        }
        try {
            const res = await axios({
                method: "GET",
                url: api_configs.listProduct,
            });
            if (res.data.responseCode === 200) {
                const filterdata = res.data.result.filter((item) => {
                    return item.type === "TRANDING";
                });
                return filterdata;
            }
            // await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulating delay
            // return cardDetails.data; // Return dummy data directly
        } catch (error) {
            throw error; // Let the rejection handle in createSlice catch this
        }
    }
);

// Slice for products
const TrandingSlice = createSlice({
    name: 'trandingproducts',
    initialState: { isLoading: false, products: [], error: null },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTrandingProducts.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllTrandingProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.products = action.payload;
                state.error = null;
            })
            .addCase(getAllTrandingProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.products = [];
                state.error = action.error.message; // Access error message
            });
    },
});



// Selectors to access state from Redux store
export const selectTranding = (state) => state.trandingproducts.products;

// Exporting reducers
export default TrandingSlice.reducer;